import React, {useEffect, useState} from 'react';
import { BackendURL, API_TOKEN } from '../components/common/Misc.js';
import Meta from '../components/common/Meta.js';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Roadmap = () => {

    const [response, setResponse] = useState(null);

    useEffect(() => {
        const headers = {'Content-type': 'application/json', 'Authorization': `Bearer ${API_TOKEN}` };
        
        fetch(`${BackendURL}/api/pages/roadmap?populate[meta][populate]=*&populate[Featured_Image]=*`, { headers })
            .then(response => response.json())
            .then(data => setResponse(data.data));
    }, []);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

    const headingStyle = {
        textTransform: "uppercase",
        letterSpacing: "5px",
        color: "#a59eff",
        WebkitTextStrokeWidth: "2px",
        WebkitTextStrokeColor: "var(--primary)",
        fontFamily: "'Jost'",
    };

  return (
    <>
    {response && response?.attributes?.meta ?
        <Meta value={response?.attributes} canonical={`https://fonimo.app/${response?.attributes?.slug}`} />
    : ""}
    <section className='roadmap-hero-blk' >
        <div className='roadmap-hero-inner'>
            <div className='container px-lg-4'>
                <div className='row justify-content-center'>
                    <div className='col-lg-12 text-center'>
                        <h1 className='roadmap_custom_heading' style={headingStyle}>Roadmap</h1>
                        <p className='text-white mt-3 px-5'><span style={{fontSize:"50px",fontWeight:"800",color:"#e8bbff"}} ></span>Our mission is to deliver the best-in-class softphone solution, providing unparalleled mobility and feature-rich functionality to our customers. We strive to achieve seamless integration with any telecom software, whether it's a call center, PBX, custom softswitch, or any other telecommunications system. With our innovative approach, we aim to revolutionize mobile communication, empowering businesses to streamline operations and elevate customer experiences.</p>
                        <div className='button_wr mt-5'>
                            <a href="/contact-us" className="btn btn-secondary-gradient py-sm-3 px-4 px-sm-5">Get In Touch</a>
                        </div>
                    </div>

                    <div className='image_wr col-8 mt-5'>
                        <figure>
                            <img src='/img/roadmap.webp' alt='roadmap' />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className='mockup-showcase-blk py-5'>
        <div className='container'>
            <div className='mockup-showcase-inner'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='section-title text-center'>
                            <h2 className='title mb-3'>Glimpse of what's new coming</h2>
                            <p>Your sneak peek into the future, bringing you the cutting-edge of tomorrow.</p>
                        </div>
                    </div>
                </div>
                <div className='mockup-showcase-slider-wr'>
                    <div className='row justify-content-center'>
                        <div className="col-12 justify-content-lg-end wow fadeInUp" data-wow-delay="0.3s">
                            <Slider {...settings}>
                                <img  alt="Chat" src="/img/roadmap/Chat.webp" />
                                <img  alt="Contact" src="/img/roadmap/Contact.webp"></img>
                                <img  alt="Calling" src="/img/roadmap/Calling.webp" />
                                <img  alt="Call Log" src="/img/roadmap/Call_Logs.webp" />
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Roadmap