import React,{useEffect} from 'react'

const Feedback = () => {

    useEffect(() => {
        document.body.classList.add('feedback-page');

        return () => {
            document.body.classList.remove('feedback-page');
        };
        }, []);

  return (
    <>
    </>
  )
}

export default Feedback