import React, {useEffect, useState} from 'react';
import { BackendURL, API_TOKEN } from '../components/common/Misc.js';
import Meta from '../components/common/Meta.js';
import ContactForm from '../components/common/ContactForm.js';

const Contact = () => {

    const [response, setResponse] = useState(null);

    useEffect(() => {
        const headers = {'Content-type': 'application/json', 'Authorization': `Bearer ${API_TOKEN}` };
        
        fetch(`${BackendURL}/api/pages/contact-us?populate[meta][populate]=*&populate[Featured_Image]=*`, { headers })
            .then(response => response.json())
            .then(data => setResponse(data.data));
    }, []);

    console.log(response);

  return (
    <>
    {response && response?.attributes?.meta ?
        <Meta value={response?.attributes} canonical={`https://fonimo.app/${response?.attributes?.slug}`} />
    : ""}
    <section className='comn-hero-blk'>
        <div className='comn-hero-inner'>
            <div className='container px-lg-5'>
                <div className='row'>
                    <div className='col-lg-6 hero-content-wr'>
                        <h1 className='text-white mb-4'>Are You Ready To Redefine Your Revenue Model? </h1>
                        <p className='text-white pb-3' style={{paddingRight:"50px"}}>With decades of collective experience from our developers, business heads, and other team members, we have developed the most advanced custom VoIP Softphone app with WebRTC support. Impress your clients and lock more business with new clients using our highly tailored white label VoIP Softphone apps for Android, iOS, and other devices.</p>
                    </div>
                    <div className='col-lg-6'>
                        <ContactForm button="secondary"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='contact-block' style={{ width: '100%' }}>
          <div className="container-xxl py-5">
            <div className="container px-lg-5">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h5 className="text-primary-gradient fw-medium">OUR CONTACT</h5>
                    <h2 className="mb-5">We’re Here to Help You</h2>
                </div>
                <div className="row g-4  justify-content-center">
                    {/* <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="feature-item  text-center bg-light rounded p-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{width: "60px", height: "60px"}}>
                                <i className="text-white fa fa-map-marker-alt"></i>
                            </div>
                            <h5 className="mb-3">Location</h5>
                            <p className="m-0">508-509, Lilamani Corporate Heights, Opp. Vadaj, BRTS Stop, Ashram Rd, Bhimjipura, Nava Vadaj, Ahmedabad, Gujarat 380013</p>
                        </div>
                    </div> */}
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="feature-item  text-center bg-light rounded p-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{width: "60px", height: "60px"}}>
                                <i className="text-white fa fa-phone-alt"></i>
                            </div>
                            <h5 className="mb-3">Phone</h5>
                            <p className="m-0"><a href='tel:+91-90236-80470'>+91-90236-80470 (India)</a></p>
                            <p className="m-0 mt-3"><a href='tel:+1-315-898-1049'>+1-315-898-1049 (USA)</a></p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="feature-item  text-center bg-light rounded p-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{width: "60px", height: "60px"}}>
                                <i className="text-white fa fa-envelope"></i>
                            </div>
                            <h5 className="mb-3">Email</h5>
                            <p className="m-0"><a href='mailto:sales@fonimo.app'>sales@fonimo.app</a></p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </section>
    <section className='map-blk'>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12 px-0'>
                    <div className='map-wr'>
                    <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3670.9463371024544!2d72.56879957592565!3d23.062428879145894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84894bd4a251%3A0xeec54afec8555ba2!2sInextrix%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1711959951235!5m2!1sen!2sin" width="100%" height="450" style={{ border: "0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Contact